import { API_URL } from './constants';
import {User} from "../types";
import { HttpClient } from "./HttpClient";

export class AuthService {
  static user: User | null = null;

  static async getUser(afterRefresh = false): Promise<User> {
    const response = await HttpClient.fetch(API_URL + 'auth/me');

    if (response.ok) {
      const user = await response.json();
      this.user = user;
      return user;
    } else if (response.status === 401) {
      if (afterRefresh) {
        return null;
      }

      let refreshResponse = await fetch(API_URL + 'auth/refresh', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (refreshResponse.ok) {
        const result = await refreshResponse.json();
        HttpClient.accessToken = result.access_token;
        return this.getUser(true);
      }

      return null;
    } else {
      throw response;
    }
  }

  static async login(email, password): Promise<void> {
    let response = await fetch(API_URL + 'auth/login', {
      method: 'POST',
      body: JSON.stringify({ email, password }),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const result = await response.json();
      this.user = result.user;
      HttpClient.accessToken = result.access_token;
    } else {
      throw response;
    }
  }

  static async logout(): Promise<void> {
    let response = await HttpClient.fetch(API_URL + 'auth/logout', {
      method: 'POST'
    });

    if (response.ok) {
      this.user = null;
      return response.json();
    } else {
      throw response;
    }
  }
}