import {FaqComponent} from "./FaqComponent";
import {PlansComponent} from "./PlansComponent";
import {FormComponent} from "./FormComponent";
import {ReviewsComponent} from "./ReviewsComponent";

export class AppComponent {
    // Initialize a page after being rendered into outlet
    static init($outlet) {
        const faqContainer = $outlet.find('[data-faq]');
        if (faqContainer.length) {
            const component = new FaqComponent(faqContainer)
            component.init();
        }

        const plansContainer = $outlet.find('[data-plans]');
        if (plansContainer.length) {
            PlansComponent.init(plansContainer);
        } else {
            PlansComponent.destruct();
        }

        $outlet.find('.form').each((index, form) => {
            const component = new FormComponent($(form));
            component.init();
        });

        ReviewsComponent.init($outlet);
    }
}