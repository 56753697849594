import { API_URL } from './constants';
import {FormErrors, User, WayforpayOrderOptions} from "../types";

export class UserService {
  static async create(data): Promise<{ user: User, orderOptions: WayforpayOrderOptions } | FormErrors> {
    let response = await fetch(API_URL + 'users', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const result = await response.json();
    switch (response.status) {
      case 400:
        throw result;
      case 500:
        throw new Error('Unknown error');
      default:
        return result;
    }
  }

  static async createSubscription(user: User, orderReference: string): Promise<void> {
    let response = await fetch(API_URL + 'payments/subscriptions', {
      method: 'POST',
      body: JSON.stringify({ user_id: user.id, order_reference: orderReference }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const result = await response.json();
    switch (response.status) {
      case 200:
        return;
      default:
        throw new Error(result.message);
    }
  }
}