export class PopupComponent {
    static isInitialized: boolean = false;

    private static _resetForms($popup) {
        $popup.find('form')
            .addBack('form')
            .each((index: number, form: HTMLFormElement) => {
                form.reset();
                $(form).find('.input-container.error').removeClass('error');
                $(form).find('.input-hint.error').remove();
            });
    }

    static initOnce() {
        if (!this.isInitialized) {
            $(document).on('click', '.popup-toggle', function () {
                const popup = $(this).data('popup');
                const $popup = $('#popup-' + popup);

                $popup.toggle();

                if ($popup.is(':hidden')) {
                    PopupComponent._resetForms($popup);
                }
            });

            $(document).on('click', (event) => {
                if (!$(event.target).closest('.popup, .popup-toggle').length) {
                    const $popups = $('.popup');
                    $popups.hide();
                    this._resetForms($popups);
                }
            });

            this.isInitialized = true;
        }
    }
}

