import moment from 'moment';
import Handlebars from 'handlebars';
import 'jquery';
import 'imask';
import 'owl.carousel';

import {App} from "./App";

Handlebars.registerHelper('isEqual', function (a, b) {
    return a === b;
});

Handlebars.registerHelper('formatPhoneNumber', function (internationalPhoneNumber) {
    if (!internationalPhoneNumber) {
        return '';
    }

    return internationalPhoneNumber.replace( /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)(\d{2})(\d{3})(\d*)$/, (match, p1, p2, p3, p4) => {
        const phone = `+${p1} ${p2} ${p3}`;
        if (!p4) {
            return phone;
        }
        return `${phone}-${p4.match(/\d{1,2}/g).join('-')}`;
    });
});

Handlebars.registerHelper('formatDate', function(format, date) {
    return new Handlebars.SafeString(moment(date).format(format).toUpperCase() );
});

Handlebars.registerHelper('ifCond', function (v1, operator, v2, options) {
    switch (operator) {
        case '==':
            return (v1 == v2) ? options.fn(this) : options.inverse(this);
        case '===':
            return (v1 === v2) ? options.fn(this) : options.inverse(this);
        case '!=':
            return (v1 != v2) ? options.fn(this) : options.inverse(this);
        case '!==':
            return (v1 !== v2) ? options.fn(this) : options.inverse(this);
        case '<':
            return (v1 < v2) ? options.fn(this) : options.inverse(this);
        case '<=':
            return (v1 <= v2) ? options.fn(this) : options.inverse(this);
        case '>':
            return (v1 > v2) ? options.fn(this) : options.inverse(this);
        case '>=':
            return (v1 >= v2) ? options.fn(this) : options.inverse(this);
        case '&&':
            return (v1 && v2) ? options.fn(this) : options.inverse(this);
        case '||':
            return (v1 || v2) ? options.fn(this) : options.inverse(this);
        default:
            return options.inverse(this);
    }
});

App.init();
