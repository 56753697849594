import {PostService} from "../services/PostService";
import {TemplateRenderer} from "../TemplateRenderer";
import {AccordionComponent} from "./AccordionComponent";

export class FaqComponent {
    $container: JQuery = null;

    constructor(element: JQuery) {
        this.$container = element;
    }

    async init() {
        const faqs = await PostService.getFaqs();
        this.$container.html(TemplateRenderer.renderTemplate('faq', {faqs}));

        new AccordionComponent(this.$container).init();
    }
}
