import {TemplateRenderer} from "./TemplateRenderer";
import {PageService} from "./services/PageService";
import {AppComponent} from "./components/AppComponent";
import {routes} from "./routes";
import {BannerComponent} from "./components/BannerComponent";

export class Router {
    static outlet: any = $('[data-router-outlet]');
    static routes = routes;
    static pageComponent: any;

    static showPage(contents) {
        this.outlet.html(contents);
        window.scrollTo(0, 0);
    }

    static showNotFoundPage() {
        this.showPage(TemplateRenderer.renderTemplate('not-found-page'));
    }

    static showErrorPage() {
        this.showPage(TemplateRenderer.renderTemplate('error-page'));
    }

    static async navigate(path, pushState = true) {
        if (pushState) {
            history.pushState(null, null, path);
        }
        let pageContents = null;

        const staticRouteKey = Object.keys(this.routes).find(route => path.startsWith(route));
        let pageComponent = null;

        if (staticRouteKey) {
            const staticRoute = this.routes[staticRouteKey];
            let data = null;
            if (staticRoute.resolver) {
                data = await staticRoute.resolver();
            }
            pageContents = TemplateRenderer.renderTemplate(staticRoute.template, data);
            pageComponent = staticRoute.component || null;

            if (pageComponent) {
                pageComponent.resolved = data;
            }
        } else {
            try {
                const page = await PageService.getOneBySlug(path.replace(/^\/(.*)\/?$/, '$1'));
                pageContents = page.html_contents;
            } catch (response) {
                if (response.status === 404) {
                    this.showNotFoundPage();
                    return;
                } else {
                    this.showErrorPage();
                    return;
                }
            }
        }

        this.showPage(pageContents);
        this.pageComponent = pageComponent;
        this.initPage();
        if (path === '/') {
            BannerComponent.init();
        } else {
            BannerComponent.destroy();
        }
    }

    static initPage() {
        AppComponent.init(this.outlet);

        if (this.pageComponent) {
            this.pageComponent.init(this.outlet, { Router: this });
        }
    }

    static async init() {
        const router = this;

        $(document).on('click', 'a[href]', function (event) {
            if (this.host === location.host) {
                event.preventDefault();

                if (this.pathname !== location.pathname || this.search !== location.search) {
                    router.navigate(this.pathname + this.search);
                } else if (this.hash) {
                    $(this.hash)[0].scrollIntoView({behavior: 'smooth'});
                    history.pushState(null, null, this.path);
                }
            }
        });

        window.addEventListener('popstate', (event) => {
            this.navigate(location.pathname, false);
        });

        await this.navigate(location.pathname + location.search, false);
    }
}
