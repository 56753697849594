import {Config, User} from "./types";
import {TemplateRenderer} from "./TemplateRenderer";
import {Router} from "./Router";
import {ConfigService} from "./services/ConfigService";
import {AuthService} from "./services/AuthService";
import {InputPasswordComponent} from "./components/InputPasswordComponent";
import {LoginPopupComponent} from "./components/LoginPopupComponent";
import {ProfilePopupComponent} from "./components/ProfilePopupComponent";

export class App {
    static user: User = null;
    static page = $('body');
    static loader = $('.loader');
    static main = $('#page-main');
    static headerOutlet = $('[data-header-outlet]');
    static footerOutlet = $('[data-footer-outlet]');
    static loginPopupOutlet = $('[data-popup-outlet="login"]');
    static profilePopupOutlet = $('[data-popup-outlet="profile"]');

    static renderHeader() {
        const data = { config: ConfigService.config, user: AuthService.user };
        this.headerOutlet.html(TemplateRenderer.renderTemplate('page-header', data)).show();
        this.profilePopupOutlet.html(TemplateRenderer.renderTemplate('profile-popup', data));
        this.loginPopupOutlet.html(TemplateRenderer.renderTemplate('login-popup', ConfigService.config));
    }

    static renderFooter() {
        this.footerOutlet.html(TemplateRenderer.renderTemplate('page-footer', ConfigService.config)).show();
    }

    static async init() {
        let [, user] = await Promise.all([ConfigService.get(), AuthService.getUser()]);
        await Router.init();
        this.setUser(user);

        $('title').text(ConfigService.config.siteName);

        this.renderHeader();
        this.renderFooter();
        this.main.show();
        this.loader.hide();
        Router.outlet.show();

        // Initialize all the component that only need this once
        LoginPopupComponent.init(this, { Router });
        ProfilePopupComponent.init(this, { Router });
        InputPasswordComponent.init();
    }

    static setUser(user) {
        this.page[user ? 'addClass': 'removeClass']('logged-in');
    }
}