import {SignupFormComponent} from "./SignupFormComponent";
import {Plan, User, WayforpayOrderOptions} from "../types";
import {PaymentFormComponent} from "./PaymentFormComponent";

export class SignupPageComponent {
    static resolved: {
        selectedPlan: string,
        plans: Plan[],
    };

    static init(outlet, {Router}) {
        const section = outlet.find('.section');
        const childOutlet = outlet.find('[data-outlet="signup"]');

        SignupFormComponent
            .init(childOutlet, this.resolved)
            .onSuccess(({ user, orderOptions }: { user: User, orderOptions: WayforpayOrderOptions }) => {
                section
                    .addClass('section-signup-payment');

                PaymentFormComponent.init(childOutlet, { user, orderOptions })
                    .onSuccess(() => {
                        Router.navigate('/thanks?email=' + user.email);
                    })
                    .onError(() => {
                        Router.navigate('/problem?email=' + user.email);
                    });
            });
    }
}