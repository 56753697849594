import {API_URL} from './constants';
import {Config} from '../types';
import {HttpClient} from "./HttpClient";

export class ConfigService {
    static config: Config | null = null;

    static async get(): Promise<Config> {
        const response = await HttpClient.fetch(API_URL + 'config');
        if (response.ok) {
            const config = await response.json();

            this.config = config;
            return config;
        } else {
            throw response;
        }
    }
}