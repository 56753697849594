export class InputPasswordComponent {
    static init() {
        $(document).on('click', '.password-visibility-toggle', function () {
            const $input = $(this).prev('.input');
            if ($input.attr('type') === 'password') {
                $input.attr('type', 'text');
            } else {
                $input.attr('type', 'password');
            }
        });
    }
}

