import {FormComponent} from "./FormComponent";
import {SignupRequest, User, WayforpayOrderOptions} from "../types";
import { UserService } from "../services/UserService";
import {RECAPTCHA_SITE_KEY} from "../constants";
import {TemplateRenderer} from "../TemplateRenderer";

export class SignupFormComponent {
    static formComponent: FormComponent;
    static recaptchaWidgetId: string;
    static successCallback: ({user, orderOptions}: { user: User, orderOptions: WayforpayOrderOptions }) => void;

    static getFormValue(): SignupRequest {
        const data: SignupRequest = ['first_name', 'email', 'phone', 'plan', 'g-recaptcha-response'].reduce<SignupRequest>((accum, key) => {
            accum[key] = this.formComponent.getFieldValue(key);
            if (key === 'phone') {
                accum[key] = accum[key].replace(/[^0-9\+]/g, '');
            }
            return accum;
        }, {} as SignupRequest);

        const lastName = this.formComponent.getFieldValue('last_name');
        if (lastName) {
            data.last_name = lastName as string;
        }

        data.accepts_conditions = this.formComponent.getField('accepts_conditions').prop('checked');

        return data;
    };

    static onSuccess(callback): typeof SignupFormComponent {
        this.successCallback = callback;
        return this;
    }

    static init($outlet, data): typeof SignupFormComponent {
        $outlet.html(TemplateRenderer.renderTemplate('form-signup', data));
        const $form = $outlet.find('#form-signup');

        this.formComponent =
            new FormComponent($form)
                .init()
                .setFieldNames(['first_name', 'email', 'phone', 'plan', 'accepts_conditions', 'g-recaptcha-response'])
                .setErrorMessages({
                    'first_name:validation.required': 'Представьтесь, пожалуйста',
                    'email:validation.required': 'Пожалуйста, введите адрес email',
                    'email:validation.email': 'Пожалуйста, введите настоящий адрес email',
                    'email:validation.unique': 'Этот адрес уже зарегистрирован',
                    'phone:validation.required': 'Пожалуйста, введите номер телефона',
                    'phone:validation.unique': 'Этот телефон уже зарегистрирован',
                    'phone:validation.regex': 'Пожалуйста, введите номер в международном формате',
                    'accepts_conditions:validation.accepted': 'Чтобы продолжить, прочтите и примите условия',
                });

        const recaptcha = window['grecaptcha'];

        $form.on('submit', (event) => {
            event.preventDefault();
            recaptcha.execute(this.recaptchaWidgetId);
        })

        recaptcha.ready(() => {
            this.recaptchaWidgetId = recaptcha.render('recaptcha', {
                sitekey: RECAPTCHA_SITE_KEY,
                size: "invisible",
                callback: async () => {
                    const button = $form.find('.button');
                    button.prop('disabled', true).addClass('disabled');
                    this.formComponent.resetErrors();
                    let response;
                    let error = false;

                    try {
                        response = await UserService.create(this.getFormValue());
                    } catch (errors) {
                        error = true;

                        if (errors instanceof Error) {
                            this.formComponent.setGenericError();
                        } else {
                            this.formComponent.setErrors(errors);
                        }

                        recaptcha.reset(this.recaptchaWidgetId);
                    }
                    button.prop('disabled', false).removeClass('disabled');

                    if (!error) {
                        this.successCallback(response);
                    }
                }
            });
        });

        return this;
    }
}
