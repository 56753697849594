import {TemplateRenderer} from "../TemplateRenderer";
import {PlanService} from "../services/PlanService";
import {getColumnsWidths} from "../helpers";
import {CONTAINER_PADDING} from "../constants";

export class PlansComponent {
    static COLUMN_WIDTH = 300;
    static COLUMN_GAP = 20;

    static $plansOwl: any = null;

    static initCarousel() {
        const width = $(window).width();
        const maxCarouselWidth = getColumnsWidths(PlansComponent.COLUMN_WIDTH, PlansComponent.COLUMN_GAP, CONTAINER_PADDING, 3);

        if (width < maxCarouselWidth && !this.$plansOwl.hasClass('owl-loaded')) {
            this.$plansOwl.owlCarousel({
                rewind: true,
                margin: 20,
                nav: false,
                dots: false,
                stagePadding: 40,
                responsiveBaseElement: $('body'),
                responsive: {
                    0: {items: 1},
                    [getColumnsWidths(PlansComponent.COLUMN_WIDTH, PlansComponent.COLUMN_GAP, CONTAINER_PADDING, 2)]: {items: 2},
                }
            });
        } else if (width >= maxCarouselWidth && this.$plansOwl.hasClass('owl-loaded')) {
            this.$plansOwl.trigger('destroy.owl.carousel');
        }

    }

    static async init($container: JQuery) {
        this.initCarousel = this.initCarousel.bind(this);
        $(window).on('resize', this.initCarousel);

        const plans = await PlanService.getPlans();
        $container.html(TemplateRenderer.renderTemplate('plans', {plans}));

        this.$plansOwl = $container.find('.carousel.plans').addClass('owl-carousel');

        this.initCarousel();
        this.$plansOwl.trigger('refresh.owl.carousel');
    }

    static destruct() {
        $(window).off('resize', this.initCarousel);
    }
}
