import {AuthService} from "./AuthService";

export class HttpClient {
    static accessToken: string | null = null;

    static fetch(url, params = {}) {
        if (this.accessToken) {
            params = {
                ...params,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken,
                    ...params['headers'],
                }
            };
        }

        return fetch(url, params);
    }
}