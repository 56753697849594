import Handlebars from 'handlebars';

export class TemplateRenderer {
    static templates: {[key: string]: any } = {};

    static renderTemplate(id: string, data: any = null) {
        if (!this.templates[id]) {
            const template = $('#template-' + id).text();
            this.templates[id] = Handlebars.compile(template);
        }

        return this.templates[id](data);
    }
}

