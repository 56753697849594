import {CONTAINER_MAX_WIDTH, CONTAINER_PADDING} from "../constants";
import {getColumnsWidths} from "../helpers";

export class ReviewsComponent {
    static COLUMN_GAP = 20;
    static BORDER_WIDTH = 6;
    static ARROW_PADDING = 50;
    static COLUMN_WIDTHS = {
        md: 200,
        lg: 300,
    };

    static init($container) {
        Object.entries(ReviewsComponent.COLUMN_WIDTHS).forEach(([size, columnWidth]) => {
            const columnMinWidth = columnWidth + ReviewsComponent.BORDER_WIDTH * 2;
            const containerPadding = CONTAINER_PADDING + ReviewsComponent.ARROW_PADDING;
            const getReviewWidth = getColumnsWidths.bind(this, columnMinWidth, ReviewsComponent.COLUMN_GAP, containerPadding);

            const responsive = {
                0: {items: 1}
            };

            for (let columnCount = 1; ; columnCount++) {
                const width = getReviewWidth(columnCount);

                if (width > CONTAINER_MAX_WIDTH) {
                    break;
                }

                responsive[width] = {items: columnCount};
            }

            ($container.find('.carousel.reviews.reviews-size-' + size)
                .addClass('owl-carousel') as any)
                .owlCarousel({
                    loop: true,
                    margin: ReviewsComponent.COLUMN_GAP,
                    nav: true,
                    dots: false,
                    responsiveBaseElement: $('body'),
                    responsive,
                });
        });
    }
}