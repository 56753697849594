import {AuthService} from "../services/AuthService";
import {PopupComponent} from "./PopupComponent";

export class ProfilePopupComponent {
    static popup = $('.popup-profile');

    static init(App, { Router }) {
        PopupComponent.initOnce();

        $(document).on('click', '.logout', async function () {
            await AuthService.logout();
            App.setUser(null);
            App.renderHeader();
            Router.initPage();
        });
    }
}