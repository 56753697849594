export class AccordionComponent {
    static isInitialized: boolean = false;

    $container: JQuery = null;

    static initOnce() {
        if (!this.isInitialized) {
            $(document).on('click', '.accordion-header', function () {
                $(this).toggleClass('open')
                    .closest('.accordion-item')
                    .find('.accordion-main')
                    .slideToggle();
            });

            this.isInitialized = true;
        }
    }

    constructor(element: JQuery) {
        this.$container = element;
    }

    init() {
        AccordionComponent.initOnce();

        const $accordion = this.$container.find('.accordion');
        $accordion.find('.accordion-main').hide();
    }
}

