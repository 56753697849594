import { API_URL } from "./constants";
import { Post } from "../types";
import {HttpClient} from "./HttpClient";

export class PostService {
    static getFaqs(): Promise<Post[]> {
        return HttpClient.fetch(API_URL + 'posts?type=faq&category:slug=landing')
            .then(response => {
                if (response.ok) {
                    return response.json();
                }

                throw response;
            });
    }
}
