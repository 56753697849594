import {API_URL} from './constants';
import {Page} from '../types';
import {HttpClient} from "./HttpClient";

export class PageService {
    static getOneBySlug(slug): Promise<Page> {
        return HttpClient.fetch(API_URL + 'page?slug=' + slug)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }

                throw response;
            });
    }
}
