import {SignupPageComponent} from "./components/SignupPageComponent";
import {PlanService} from "./services/PlanService";
import {AuthService} from "./services/AuthService";
import {ConfigService} from "./services/ConfigService";
import {Router} from "./Router";

export const routes = {
    '/signup': {
        template: 'signup-page',
        resolver: async () => {
            if (AuthService.user) {
                // Redirect signed in users to cabinet
                window.location.href = ConfigService.config.cabinetBaseUrl;
                return;
            }

            return {
                selectedPlan: new URLSearchParams(location.search).get('plan') || null,
                plans: await PlanService.getPlans()
            };
        },
        component: SignupPageComponent,
    },
    '/thanks': {
        template: 'thanks-page',
        resolver: () => {
            if (AuthService.user) {
                // Redirect signed in users to cabinet
                window.location.href = ConfigService.config.cabinetBaseUrl;
                return;
            }

            const email = new URLSearchParams(location.search).get('email') || null;

            if (!email) {
                Router.navigate('/');
                return;
            }

            return {
                email,
            };
        },
    },
    '/problem': {
        template: 'problem-page',
        resolver: () => {
            if (AuthService.user) {
                // Redirect signed in users to cabinet
                window.location.href = ConfigService.config.cabinetBaseUrl;
                return;
            }

            const email = new URLSearchParams(location.search).get('email') || null;

            if (!email) {
                Router.navigate('/');
                return;
            }

            return {
                email,
            };
        },
    },
};
