import moment from 'moment';
import {TemplateRenderer} from "../TemplateRenderer";
import {ConfigService} from "../services/ConfigService";

export class BannerComponent {
    static interval: any = null;
    static $container: JQuery = $('#banner')

    static pluralize(count, one, few, many) {
        switch (true) {
            case count % 10 === 1 && count !== 11:
                return one;
            case [2, 3, 4].includes(count % 10) && ![12, 13, 14].includes(count):
                return few;
            default:
                return many;
        }
    }

    static render() {
        const {wholesaleUntil, wholesaleSince, wholesaleDiscount} = ConfigService.config;
        let momentUntil = moment(wholesaleUntil);

        // If the wholesale is not set, not over or not started, do nothing
        if (!wholesaleDiscount || (wholesaleUntil && momentUntil.isBefore()) || (wholesaleSince && moment(wholesaleSince).isAfter())) {
            this.destroy();

            return false;
        }

        const now = moment();

        // Find the end of the next even day in the year.
        const endOfNextEvenDay = now.clone().endOf('day');
        if (now.dayOfYear() % 2 === 1) {
            endOfNextEvenDay.add(1, 'days');
        }

        // If the wholesale runs beyond the end of the even day, count down to that end of the even day.
        if (!wholesaleUntil || momentUntil.isAfter(endOfNextEvenDay)) {
            momentUntil = endOfNextEvenDay;
        }

        const data = {
            description: ConfigService.config.wholesaleDescription,
            labels: {
                days: '',
                hours: '',
                minutes: '',
                seconds: '',
            },
            days: momentUntil.diff(now, 'days'),
            hours: momentUntil.diff(now, 'hours') % 24,
            minutes: momentUntil.diff(now, 'minutes') % 60,
            seconds: momentUntil.diff(now, 'seconds') % 60,
        };

        data.labels.days = this.pluralize(data.days, 'день', 'дня', 'дней');
        data.labels.hours = this.pluralize(data.hours, 'час', 'часа', 'часов');
        data.labels.minutes = this.pluralize(data.minutes, 'минута', 'минуты', 'минут');
        data.labels.seconds = this.pluralize(data.seconds, 'секунда', 'секунды', 'секунд');

        this.$container
            .show()
            .find('[data-outlet="indicator"]')
            .html(TemplateRenderer.renderTemplate('banner', data));

        return true;
    }

    static init() {
        if (BannerComponent.interval) {
            clearInterval(BannerComponent.interval);
        }

        const result = this.render();

        if (result) {
            BannerComponent.interval = setInterval(() => {
                BannerComponent.render();
            }, 1000);
        }
    }

    static destroy() {
        if (BannerComponent.interval) {
            clearInterval(BannerComponent.interval);
        }

        this.$container.hide();
    }
}
