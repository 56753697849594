import { API_URL } from "./constants";
import { Plan } from "../types";
import {HttpClient} from "./HttpClient";

export class PlanService {
    static getPlans(): Promise<Plan[]> {
        return HttpClient.fetch(API_URL + 'plans')
            .then(response => {
                if (response.ok) {
                    return response.json();
                }

                throw response;
            });
    }
}