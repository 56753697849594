import IMask, {AnyMaskedOptions} from "imask";
import {FormErrors, FormMessages} from "../types";

export class FormComponent {
    static phoneMaskOptions: AnyMaskedOptions = {
        mask: '+00000[0000000000]'
    };

    private $form: JQuery = null;
    private messages: FormMessages = {};
    private fieldNames: string[] = [];

    setFieldNames(fieldNames: string[]): FormComponent {
        this.fieldNames = fieldNames;
        return this;
    }

    getField(fieldName: string): JQuery {
        return this.$form.find('[name="' + fieldName + '"]');
    }

    getFieldContainer(fieldName: string): JQuery {
        return this.getField(fieldName).closest('.input-container');
    }

    getFieldValue(fieldName: string): any {
        return this.getField(fieldName).val();
    }

    setErrorMessages(messages: FormMessages): FormComponent {
        this.messages = messages;
        return this;
    }

    setErrors(errors: FormErrors): FormComponent {
        Object.keys(errors).forEach((key) => {
            // Only capable to show one error line at a time
            this.setError(key, errors[key][0]);
        })

        return this;
    }

    getErrorMessage(fieldName: string, errorCode: string): string {
        return this.messages[`${fieldName}:${errorCode}`] || this.messages[errorCode] || '';
    }

    setError(fieldName: string, errorCode: string): FormComponent {
        this.getFieldContainer(fieldName)
            .addClass('error')
            .append('<div class="input-hint error">' + this.getErrorMessage(fieldName, errorCode) + '</div>');

        return this;
    }

    resetError(fieldName: string): FormComponent {
        this.getFieldContainer(fieldName)
            .removeClass('error')
            .find('.input-hint.error')
            .remove();

        return this;
    }

    setGenericError(): FormComponent {
        this.$form.find('.actions').append('<p class="error">Произошла ошибка. Пожалуйста, попробуйте позже.</p>');
        return this;
    }

    resetGenericError(): FormComponent {
        this.$form.find('.actions p.error').remove();
        return this;
    }

    resetErrors(): FormComponent {
        this.resetGenericError();
        this.fieldNames.forEach(fieldName => this.resetError(fieldName));
        return this;
    }

    initPhones(): void {
        this.$form.find('.input-phone').each((index, element) => {
            IMask(element, FormComponent.phoneMaskOptions);
        });
    }

    constructor(element: JQuery) {
        this.$form = element;
    }


    init() {
        this.initPhones();

        this.$form.on('keyup change', '.input, [type="checkbox"]', (event) => {
            this.resetError(event.target.name);
        })

        return this;
    }
}
