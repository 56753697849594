import {User, WayforpayOrderOptions} from "../types";
import {TemplateRenderer} from "../TemplateRenderer";
import {UserService} from "../services/UserService";

export class PaymentFormComponent {
    private static wayforpay = new (window as any).Wayforpay();
    static successCallback: () => void;
    static errorCallback: () => void;
    static eventListener: (event: any) => Promise<void>;

    static init($outlet, data: { user: User, orderOptions: WayforpayOrderOptions }): typeof PaymentFormComponent {
        $outlet.html(TemplateRenderer.renderTemplate('payment', data));

        const $payButton = $outlet.find('#pay');
        $payButton.click((event) => {
            $outlet.find('.error').hide();
            $(event.target).prop('disabled', true);

            if (this.eventListener) {
                window.removeEventListener('message', this.eventListener);
            }

            this.eventListener = async (event) => {
                if(
                    event.data === "WfpWidgetEventClose" ||      //при закрытии виджета пользователем
                    event.data === "WfpWidgetEventApproved" ||   //при успешном завершении операции
                    event.data === "WfpWidgetEventDeclined" ||   //при неуспешном завершении
                    event.data === "WfpWidgetEventPending")      // транзакция на обработке
                {
                    if (event.data === "WfpWidgetEventApproved") {
                        try {
                            await UserService.createSubscription(data.user, data.orderOptions.orderReference);
                            this.successCallback();
                        } catch (err) {
                            $outlet.find('.error').show().text(err.message);
                        }
                    } else if (['WfpWidgetEventDeclined', 'WfpWidgetEventPending'].includes(event.data)) {
                        this.errorCallback();
                    }

                    $payButton.prop('disabled', false);
                }
            };

            window.addEventListener("message", this.eventListener);
            this.wayforpay.run(data.orderOptions);
        });

        return this;
    }

    static onSuccess(callback): typeof PaymentFormComponent {
        this.successCallback = callback;
        return this;
    }

    static onError(callback): typeof PaymentFormComponent {
        this.errorCallback = callback;
        return this;
    }
}
