import {FormComponent} from "./FormComponent";
import {AuthService} from "../services/AuthService";
import {PopupComponent} from "./PopupComponent";
import {ConfigService} from "../services/ConfigService";
import {HttpClient} from "../services/HttpClient";

export class LoginPopupComponent {
    static popup = $('#popup-login');

    static init(App, { Router }) {
        const form = new FormComponent(this.popup);
        form
            .init()
            .setFieldNames(['email', 'password'])
            .setErrorMessages({
                'email:invalid': '',
                'email:inactive': '',
                'password:invalid': 'Неверный адрес или пароль',
                'password:inactive': 'Ваш аккаунт неактивен. Обратитесь к администратору',
            });

        PopupComponent.initOnce();

        this.popup.on('submit',async (event) => {
            const button = this.popup.find('.button');
            event.preventDefault();
            button.prop('disabled', true);
            try {
                form.resetErrors();
                await AuthService.login(this.popup.find('[name=email]').val(), this.popup.find('[name=password]').val());
                App.setUser(AuthService.user);
                this.popup.hide();
                App.renderHeader();
                Router.initPage();
                window.location.href = ConfigService.config.cabinetBaseUrl;
            } catch (response) {
                switch (response.status) {
                    case 401:
                        form.setErrors({
                            'email': ['invalid'],
                            'password': ['invalid'],
                        });
                        break;

                    case 403:
                        form.setErrors({
                            'email': ['inactive'],
                            'password': ['inactive'],
                        });
                        break;

                    default:
                        form.setGenericError();
                }
            }
            button.prop('disabled', false);
        });

        this.popup
            .on('click', '.forgot-password', () => {
                this.popup.hide();
                form.resetErrors();
                (this.popup.get(0) as HTMLFormElement).reset();
            })
            .on('keyup change', '.input, [type="checkbox"]', (event) => {
                form.resetErrors();
            });
    }
}
